
import { number, string } from 'vue-types';
export default {
  inheritAttrs: false,
  props: {
    imgClass: string(),
    src: string().isRequired,
    mimeType: string().isRequired,
    width: number(),
    height: number(),
  },
  data() {
    return {
      seen: false,
      loaded: false,
      IntersectionObserver: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.video) {
        this.initIntersectionObserver();
      }
    });
  },
  beforeDestroy() {
    if (this.IntersectionObserver) this.IntersectionObserver.disconnect();
  },
  methods: {
    play() {
      if (this.loaded && this.$refs.video && this.$refs.video.paused) {
        this.$refs.video.play();
      }
    },
    pause() {
      if (this.loaded && this.$refs.video && !this.$refs.video.paused) {
        this.$refs.video.pause();
      }
    },
    initIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '25px',
        threshold: 0,
      };

      this.IntersectionObserver = new IntersectionObserver((entries) => {
        const intersecting = !!this.$first(entries).isIntersecting;

        if (intersecting && !this.seen) {
          this.seen = true;
        } else if (intersecting && this.seen) {
          this.play();
        } else if (!intersecting && this.seen) {
          this.pause();
        }
      }, options);

      this.IntersectionObserver.observe(this.$refs.video);
    },
  },
};
